import {
  login,
  logout,
  getInfo
} from '@/api/user'


import Cookies from 'js-cookie'

import {
  getToken,
  setToken,
  removeToken,
  setSuper,
  getSuper,

} from '@/utils/auth'
import router, {
  resetRouter
} from '@/router'
import { Row } from 'element-ui'

const state = {
  secretKey:'12321312321',
  token: sessionStorage.getItem('token') === undefined ? '' : sessionStorage.getItem('token'),
  userinfo: sessionStorage.getItem('userinfo') === undefined ? '' : sessionStorage.getItem('userinfo'),
  menu: sessionStorage.getItem('menu') === undefined ? '' : sessionStorage.getItem('menu'),
  button: sessionStorage.getItem('button') === undefined ? '' : sessionStorage.getItem('button'),
  cartdata: localStorage.getItem('cartdata') === undefined ? '' : localStorage.getItem('cartdata'),
  currentstatus:localStorage.getItem('currentstatus') === undefined ? null : localStorage.getItem('currentstatus')
}

const mutations = {

  setStatus: (state,currentstatus ) => {
    localStorage.setItem('currentstatus', currentstatus);
    state.currentstatus = currentstatus
  },

  SET_TOKEN: (state, token) => {
   
    sessionStorage.setItem('token', token);
    state.token = token
  },

  SET_USERINFO: (state, userinfo) => {
   
    sessionStorage.setItem('userinfo', JSON.stringify(userinfo));
    state.userinfo = JSON.stringify(userinfo)
  },
  SET_MENU: (state, menu) => {

    state.menu = JSON.stringify(menu)
    sessionStorage.setItem('menu', JSON.stringify(menu));

  },


  SET_BUTTON: (state, button) => {
  
    state.button = JSON.stringify(button)
    sessionStorage.setItem('button', JSON.stringify(button));
  },


  updateChecked:(state,data,index,item)=>{
    data[index] = item
    //存储到localstorage
    state.cartdata = JSON.stringify(data)
    localStorage.setItem('cartdata', JSON.stringify(data));
  },
  setCartData:(state,cartdata)=>{
    // console.log('setCartData',cartdata)
     //更新state
     state.cartdata = JSON.stringify(cartdata)
     //存储到localstorage
     localStorage.setItem('cartdata', JSON.stringify(cartdata));
  },

  addCart:(state,data)=>{
    // console.log(state,'data',data,'enddata')
      let cartdata 
    //处理数据
      data.addtime  = new Date().getTime() //添加时间
  

      if(state.cartdata){
        
         cartdata = JSON.parse(state.cartdata)
        //  console.log('cartdata',cartdata)
        if(cartdata.some(item=>item.id === data.id)){ //购物车是否有当前数据 有则更新数量 否则添加数据
          cartdata.map(row=>{
            if(row.id === data.id){
              row.count = row.count + 1
            }
            return row
          })
        }else{
          cartdata.push(data)
        }
       
      }else{
       
        cartdata = [data]
      }
    //更新state
    state.cartdata = JSON.stringify(cartdata)
    //存储到localstorage
    localStorage.setItem('cartdata', JSON.stringify(cartdata));
  }


}

const actions = {




  login({
    commit
  }, userInfo) {
    const {
      username,
      password
    } = userInfo
    return new Promise((resolve, reject) => {
      login({
        username: username.trim(),
        password: password
      }).then(response => {
        const {
          data
        } = response
        //全部存储到sessionStorage 和vuex中
        commit('SET_TOKEN', data.token) //将token 存储到
        commit('SET_USERINFO', data.member_info)
        
        if(data.menu){
          commit('SET_MENU', data.menu)
        }
        if(data.button){
          commit('SET_BUTTON', data.button)
        }
        
       
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },



  // get user info
  getInfo({
    commit,
    state
  }) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        const {
          data
        } = response

        // console.log('store 中的getInfo方法')
        if (!data) {
          reject('Verification failed, please Login again.')
        }

        // const { id, account, roles } = data
        const {
          truename,
          menu,
          button
        } = data
        // console.log('store 中的getInfo方法2')



        commit('SET_NAME', truename)
        commit('SET_MENU', menu)
        commit('SET_BUTTON', button)

        resolve(data)
      }).catch(error => {
        // console.log('store 中的getInfo方法error')
        reject(error)
      })
    })
  },




  // user logout
  logout({
    commit,
    state
  }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then((res) => {
        const {
          data
        } = res.data
        
        commit('SET_TOKEN', '')
        commit('SET_USERINFO','')
        commit('SET_MENU', '')
        commit('SET_BUTTON','')


        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({
    commit
  }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({
    commit,
    dispatch
  }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const {
      roles
    } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, {
      root: true
    })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, {
      root: true
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}