import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import 'lib-flexible'
import VueLazyLoad from 'vue-lazyload'
import dragVerify from 'vue-drag-verify2'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import '/public/css/main.css'
import './styles/element-variables.scss'
import directivePermission from '@/utils/directivePermission.js'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "bootstrap-icons/font/bootstrap-icons.css"

Vue.config.productionTip = false

Vue.prototype.$axios = axios

Vue.use(ElementUI)
Vue.use(dragVerify)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(VueLazyLoad, {
  loading: require('@/assets/icon/loading.png'),
})

Vue.directive('permission', directivePermission)

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
