import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import Cookies from 'js-cookie'

Vue.use(Vuex)
/**
 * 获得能通过第二个函数参数的值
 *
 * @param {Array} list
 * @param {Function} f
 * @return {*}
 */
export function find(list, f) {
  const {
    length
  } = list
  let index = 0
  let value
  while (++index < length) {
    value = list[index]
    if (f(value, index, list)) {
      return value
    }
  }
}

// import {
//   citylist
// } from '../api/cityresearch.js'

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

export default new Vuex.Store({


  state: {
    currentRoute: {},
    itemType:0,
   
    theme: localStorage.getItem('theme') === undefined ? 0 : parseInt(localStorage.getItem('theme')),//0 light 1dark
    hasAcceptedCookies: Cookies.get('user-has-accepted-cookies') === undefined ? false : (Cookies.get('user-has-accepted-cookies')==='true'? true : false),
    country_name: (localStorage.getItem('country_name') === undefined || localStorage.getItem('country_name') == null )? 'unknow' : parseInt(localStorage.getItem('country_name')),
  },

  getters: {
    ...getters,
    hasAcceptedCookies: state => state.hasAcceptedCookies
  
   
  },

  mutations: {
    country_name(state,v) {
      state.country_name = v;
      localStorage.setItem('country_name', v);
    },
    acceptCookies(state) {
      state.hasAcceptedCookies = true;
    },

    setRoute(state, v) {
      state.currentRoute = v
    },

    setItemType(state, v) {
      state.itemType = v
    },

    theme(state, v) {
      state.theme = v
      localStorage.setItem('theme', v);
    },
  },

  actions: {
 
  },
  modules,
  }
)

