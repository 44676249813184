<template>
  <div class="cookie-alert bg3 p-3" v-if="!$store.state.hasAcceptedCookies">
    <p class="mb-1">
      We use cookies to provide you with the best experience. Click accept and
      continue.<el-button class="ms-3" type="primary" @click="acceptCookies" size="small"
        >Accept cookies</el-button
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "CookieAlert",
  methods: {
    acceptCookies() {
      this.$store.commit("acceptCookies");
      this.setCookie("user-has-accepted-cookies", "true", 365);
    },
    setCookie(name, value, expireDays) {
      let date = new Date();
      date.setDate(date.getDate() + expireDays);
      document.cookie =
        name + "=" + value + ";expires=" + date.toUTCString() + ";path=/";
    },
  },
};
</script>

<style scoped>
.cookie-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  text-align: center;
  .el-button {
    background-color: var(--bs-primary);
  }
}
</style>
