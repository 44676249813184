import Cookies from 'js-cookie'

const TokenKey = 'token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token,{
    path: '/',
    expires:7,
  	domain: process.env.VUE_APP_DOMAIN
  })
}




export function removeToken() {
  return Cookies.remove(TokenKey)
}
