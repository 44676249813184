export default {
	//找回密码
	'Retrieve password':'Retrieve password',
	'newPassword':'New Password',
	'newPasswordTip':'Enter a desired new Password',

	'ConfirmnewPassword':'Confirm new Password',
	'ConfirmnewPasswordTip':'Enter the same  new Password again',

	//登录
	'Please submit an':"Please submit an",
	'application':"application",
	'to get access products and pricing':"to get access products and pricing.",
	'Enter your email address':'Enter your email address',
	'Lost your password':'Lost your password?',
	'Log in':'Log in',
	'Login':'Login',
	
	'Remember Me':'Remember Me',
	'Open Account':'Open Account',

	//注册验证
	'verify-email':'Please fill in your email address correctly',
	'verify-required':'Current content is mandatory',
	'verify-stringCheck':'The two password inputs are inconsistent',


	//底部
	'copyright':"© Copyright 2020-2025 || All Rights Reserved",
	//注册页面
	'Be Our Business Partner or':'Be Our Business Partner or',
	'Login Now':'Login Now',
	'Register': 'Register',
	'user':'E-mail',
	'usertip':'We need to send a verification code to your email address in order to complete the registration.',
	'Captcha':'Captcha',
	'CaptchaTip':'Please check your email for the digital verification code.(Note: The code is valid for 10 mins)',
	'getcode':'Get verification code',
	'Password':'Password',
	'PasswordTip':'Enter a desired Password',



	'ConfirmPassword':'Confirm Password',
	'ConfirmPasswordTip':'Enter the same Password again',

	
	'ClientType':'Client Type',
	'ClientTypeTip':'Select the client type',
	'ClientType1':'Wholesaler',
	'ClientType2':'Retailer',
	'ClientType3':'Partners',
	'Centertip':'After reviewing your application, we will determine the discount you will receive as our business partner.',
	'Company Name':"Company name",
	'Company Name Tip':"Your company's official name.",
	'Company Tax ID':'Company Tax ID',
	'Company Tax ID Tip':'(Optional) Federal or National Tax ID number for your business. The content of this field is kept private and will not be shown publicly..',
	'Company job position':'Company job position',
	'Company job position Tip':"Your company's job position.",
	'Contact Person':"Contact Person",
	'Contact Person Tip':"Full name of your official company contact.",
	'Contact Phone':"Company Phone",
	'Contact Phone Tip':"Your company's official contact number.",
	'Website or Third-Party Store URL':'Website or Third-Party Store URL:',
	'Website or Third-Party Store URL Tip':"(Optional) Webpage or Store ID for eBay, Amazon, NewEgg, Sears, WalMart, Etsy, etc. Please include http:// or https://",
	'Allow notifications to be accepted':"Allow notifications to be accepted",
	'Submit':'Submit',

}
