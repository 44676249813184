import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const allproducts = ()=> import('../views/website/allproducts')



const originalPush = VueRouter.prototype.push
    //修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
        return originalPush.call(this, location).catch(err => err)
    }



const asyncRoutes = [


]


const constantRoutes = [


  {
    path: '/',
    redirect: '/allproducts',
    meta: {
      title: 'Home',
      menu: 'index'
    }
  },

  // {
  //   path: '/index',
  //   name: 'index',
  //   component: () => import('../views/website/index'),
  //   meta: {
  //     title: '首页',
  //     menu: 'index'
  //   }
  // },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/website/home'),
    meta: {
      title: 'Home',
      menu: 'index'
    }
  },
  {
    path: '/allproducts',
    name: 'allproducts',

    component: allproducts,
    meta: {
      title: 'AllProucts',
      menu: 'index'
    }
  },
  {
    path: '/futer',
    name: 'futer',
    component: () => import('../views/website/futer'),
    meta: {
      title: 'Futer',
      menu: 'index'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/website/register'),
    meta: {
      title: 'Register',
      menu: 'index'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/website/login'),
    meta: {
      title: 'Login',
      menu: 'index'
    }
  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import('../views/website/forget'),
    meta: {
      title: 'Forget',
      menu: 'index'
    }
  },

  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/website/cart'),
    meta: {
      title: 'Cart',
      menu: 'index'
    }
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component: () => import('../views/website/detail'),
    meta: {
      title: 'Detail',
      menu: 'index'
    }
  },
  
  //订单确认
  {
    path: '/order/confirm/:orderno',
    name: 'orderconfirm',
    component: () => import('../views/website/order/confirm'),
    meta: {
      title: 'OrderConfirm',
      menu: 'index'
    }
  },
  
  //订单列表  
  {
    path: '/order/list',
    name: 'orderlist',
    component: () => import('../views/website/order/list'),
    meta: {
      title: 'OrderList',
      menu: 'index'
    }
  },
   //订单详情
   {
    path: '/order/detail/:orderno',
    name: 'orderdetail',
    component: () => import('../views/website/order/detail'),
    meta: {
      title: 'OrderDetail',
      menu: 'index'
    }
  },
    //订单物流追踪
    {
    path: '/order/track/:orderno',
    name: 'ordertrack',
    component: () => import('../views/website/order/track'),
    meta: {
      title: 'OrderTracking',
      menu: 'index'
    }
  },


  //账号  
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/website/account'),
    meta: {
      title: 'account',
      menu: 'index'
    }
  },
  
  //消息
  {
    path: '/message',
    name: 'message',
    component: () => import('../views/website/message'),
    meta: {
      title: 'message',
      menu: 'index'
    }
  },

   //关于
   {
    path: '/about',
    name: 'about',
    redirect: '/contact',
  },

   //copyright
   {
    path: '/copyright',
    name: 'copyright',
    redirect: '/contact',
  },
  //faq
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/website/faq.vue'),
    meta: {
      title: 'faq',
      menu: 'index'
    }
  },

   //contact
   {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/website/contact.vue'),
    meta: {
      title: 'contact',
      menu: 'index'
    }
  },

   //关于
   {
    path: '/bankinfo',
    name: 'bankinfo',
    
    redirect: '/contact',
  },
  //订单  回传用户确认页
  {
    path: '/modify',
    name: 'modify',
    component: () => import('../views/website/modify.vue'),
    meta: {
      title: 'modify',
      menu: 'index'
    }
  },


]


const routes = constantRoutes

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})





export default router

export {
  routes,
  constantRoutes,
  asyncRoutes
}