import request from '@/utils/request'

// 定义全局变量 $location
const $location = process.env.VUE_APP_BASE_API; 

//获取用户信息
export function getInfo() {
  // return memberRequest({
  //   url: '/members/info',
  //   method: 'post',
  //   params: {
  //     'guard_name': 'website-pc-member'
  //   }
  // })


}




export function login(data) {
  return request({
    url: `${$location}frontend/member/login`,
    method: 'post',
    params: data
  })



}



export function logout() {
  // return requestUcenter({
  //   url: '/admin/logout',
  //   method: 'post'
  // })
  return new Promise((resolve, reject) => {

    const res = {
      data:{
        data: {
          message:'退出成功'
        },
        code:1
      }
     

    }
  
  return resolve(res)

})

}


