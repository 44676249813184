export default {

	// 登录
	'Please submit an': '请提交',
	'application': '申请',
	'to get access products and pricing': '以获取产品和价格权限',
	'Enter your email address': '请输入您的手机号',
	'Lost your password': '忘记密码了？',
	'Log in': '登录',
	'Login':'登录',
	'Remember Me': '记住我',
	'Open Account': '开设账户',

	// 注册验证
	'verify-email': '请正确填写您的电子邮件地址',
	'verify-required': '当前内容为必填项',
	'verify-stringCheck': '两次输入的密码不一致',
	//底部
	'copyright':"Shang Hai Macnn E-commerce Co.,Ltd. © 2021",
	//注册页面
	'Be Our Business Partner or':'成为我们的业务合作伙伴或',
	'Login Now':'立即登录',
	'Register': '注册',
	'user':'手机号',
	'usertip': '为了完成注册，我们需要向您的发送一个短信验证码。',
	'Captcha': '验证码',
	'CaptchaTip': '请检查您的短信以获取数字验证码。（注意：该验证码有效期为10分钟）',
	'getcode': '获取验证码',
	'Password': '密码',
	'PasswordTip': '请输入您想要设置的密码',
	'ConfirmPassword': '确认密码',
	'ConfirmPasswordTip': '再次输入相同的密码',
	'ClientType': '客户类型',
	'ClientTypeTip': '请选择客户类型',
	'ClientType1': '批发商',
	'ClientType2': '零售商',
	'ClientType3': '合作伙伴',
	'Centertip': '在审核您的申请后，我们将确定您作为我们的业务合作伙伴所能享受的折扣。',
	'Company Name': '公司名称',
	'Company Name Tip': '贵公司的正式名称。',
	'Company Tax ID': '公司税号',
	'Company Tax ID Tip': '(可选) 贵公司税务识别号码。此字段内容将被保密，不会公开显示。',
	'Company job position': '公司职位',
	'Company job position Tip': '贵公司的职位信息。',
	'Contact Person': '联系人',
	'Contact Person Tip': '贵公司正式联系人的全名。',
	'Contact Phone': '公司电话',
	'Contact Phone Tip': '贵公司官方联系电话',
	'Website or Third-Party Store URL': '网站或第三方店铺网址',
	'Website or Third-Party Store URL Tip': '(可选) 您在eBay、Amazon、NewEgg、Sears、WalMart、Etsy等平台上的网店网址或店铺ID，请包含http:// 或 https://',
	'Allow notifications to be accepted': '允许接收通知',
	'Submit': '提交',

}