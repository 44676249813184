import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from './zh'
import en from './en'

Vue.use(VueI18n)

const messages = {
  zh,
  en
}

const i18n = new VueI18n({
  locale: 'en',  // 设置默认语言为英文
  messages
})

export default i18n